
import React, { useContext } from 'react';
import {getDocs, collection, setDoc, doc} from "firebase/firestore";

import { db } from '../firebase';

const DatabaseContext = React.createContext();

export function useDb() {
	return useContext(DatabaseContext);
};

export default function DatabaseProvider({children}) {
	async function getAllUsers() {
		return getDocs(collection(db, "users"));
	};

	async function updateUserData(userId = "", data = {}) {
		return setDoc(doc(db, "users", userId), data, {merge: true});
	};

	const value = {
		getAllUsers,
		updateUserData,
	};

	return (
		<DatabaseContext.Provider value={value}>
			{children}
		</DatabaseContext.Provider>
	);
};
