import React, {useState, useEffect, useContext, createContext} from "react";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import {
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";

import {auth, db} from "../firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);

  function adminSignIn(creds) {
    return signInWithEmailAndPassword(auth, creds.email, creds.password);
  }

  async function adminSignOut() {
    return await signOut(auth).then(() => {
      setCurrentUser({})
    })
  }

  async function getUserData(uid) {
    return await getDoc(doc(db, 'users', uid)).then(doc => {
      return doc.data()
    })
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if(user) {
        const userData = await getUserData(user.uid)
        setCurrentUser({...user, ...userData})
      }
      setLoading(false)
    })
    return unsubscribe
  }, []);

  const value = {
    currentUser,
    adminSignIn,
    adminSignOut,
  }

  return (
    <AuthContext.Provider value={value}>
      { !loading && children }
    </AuthContext.Provider>
  )
};