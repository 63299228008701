import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDhH-vccaMheiMjm3vPE6nyLzjFQ002rTs",
  authDomain: "spinecare-mvp.firebaseapp.com",
  databaseURL: "https://spinecare-mvp-default-rtdb.firebaseio.com",
  projectId: "spinecare-mvp",
  storageBucket: "spinecare-mvp.appspot.com",
  messagingSenderId: "187742829393",
  appId: "1:187742829393:web:0fef9ee8b6869278bf7170",
  measurementId: "G-ZGHKLHK2Y5"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export {app, analytics, db, auth};