import React, {useState} from "react";
import {
  Card,
  Button,
  TextField,
  Container,
  Typography,
  CardContent,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router-dom";

import {getFirebaseErrorMessage} from "../../services/firebaseHandler";
import {useAuth} from "../../contexts/AuthContext";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "100vh",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      display: 'flex !important',
      overflowY: "auto",
      background: `linear-gradient(0deg, rgba(66, 79, 176, 0.89) 0%, rgba(60, 134, 244, 0.17151) 83.33%, rgba(60, 134, 244, 0) 100%), ${theme.palette.primary.main}`,      
    },
    cardTitleStyle: {
      textAlign: "center"
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
    },
    inputClass: {
      width: "30vw",
      margin: "10px 0 10px 0 !important",
    },
    appLogo: {
      height: 200,
      width: "auto",
      marginBottom: 30,
    },
    errorMsg: {
      color: theme.palette.error.main,
      marginBottom: "10px !important",
    },
  }
});

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const styles = useStyles();
  const {adminSignIn} = useAuth();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await adminSignIn({email, password});
      history.replace("/admin");
    } catch (error) {
      const msg = getFirebaseErrorMessage(error);
      setError(msg);
    }
  };

  return (
    <Container className={styles.container} maxWidth={false}>
      <img alt="transparent app logo" className={styles.appLogo} src={require("../../assets/img/backroad-icon-trans.png").default}/>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography className={styles.cardTitleStyle} variant="h5">BackRoad SignIn</Typography>
          <form onSubmit={handleSubmit} className={styles.inputContainer}>
            <TextField
              required
              id="email"
              type="email"
              value={email}
              label="Email Address"
              className={styles.inputClass}
              onChange={(e) => {
                setEmail(e.target.value);
                if (error) setError("");
              }}
            />
            <TextField
              required
              id="password"
              type="password"
              value={password}
              label="Password"
              className={styles.inputClass}
              onChange={(e) => {
                setPassword(e.target.value);
                if (error) setError("");
              }}
            />
              {error ? <Typography className={styles.errorMsg}>{error}</Typography> : null}
            <Button
              fullWidth
              type="submit"
              variant="contained"
            >
              SignIn
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SignIn;