import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as API from "./../services/api.js";
import axios from "axios";
import {
  Box,
  List,
  Divider,
  Toolbar,
  ListItem,
  IconButton,
  CssBaseline,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer
} from "@mui/material";

const useStyles = makeStyles(theme => ({
  headerText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  }
}));

const UserAssessmentReport = () => {
  const styles = useStyles();
  const [html, setHtml] = React.useState("");

  useEffect(() => {
    console.log(window.location.search.split("=")[1]);

    var config = {
      method: "get",
      url: "https://api.backroad.health/v1/?fn=getUserAssReport&apikey=113C7BABEBF4B872BEAC115F26EBB&session_id=123123123123",
      headers: {}
    };

    axios(config)
      .then(function (response) {
        if (response.data[0].data) {
          setHtml(String(response.data[0].data));
          console.log(String(response.data[0].data));
        } else {
          alert("Error getting report. Please contact support.");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <CssBaseline />

      <Toolbar style={{ backgroundColor: "#3CC8F4" }}>
        <img
          alt="app-logo"
          style={{ height: 45, width: "auto", borderRadius: 5 }}
          src={require("../assets/img/app-logo.png").default}
        />
      </Toolbar>
      <div style={{ padding: 10 }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

export default UserAssessmentReport;
