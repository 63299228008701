export const getFirebaseErrorMessage = (error) => {
  const {message = ""} = error;
  if (message.includes("auth/network-request-failed")) {
    return "Network timeout";
  } else if (message.includes("auth/operation-not-allowed")) {
    return "Sign in/out not enabled";
  } else if (message.includes("auth/too-many-requests")) {
    return "You perform too many request for this account";
  } else if (message.includes("auth/user-disabled")) {
    return "Your account is currently disabled";
  } else if (message.includes("auth/wrong-password")) {
    return "Please enter valid password";
  } else if (message.includes("auth/user-not-found")) {
    return "Please enter valid user email";
  } else {
    return "Something went wrong!";
  }
}