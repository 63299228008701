import {Dashboard, People, AddAlert} from "@mui/icons-material";

const routes = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: Dashboard,
  },
  {
    path: "/admin/users",
    name: "Users",
    icon: People,
  },
  {
    path: "/admin/send-notification",
    name: "Send Notification",
    icon: AddAlert,
  }
];

export default routes;