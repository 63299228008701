import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import {useSnackbar} from "notistack";
import {makeStyles} from "@mui/styles";
import { styled } from '@mui/material/styles';
import {tableCellClasses} from "@mui/material/TableCell";

import {useDb} from "../../contexts/DatabaseContext";

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "600",
  }
}));

const columns = [
  {id: "id", label: 'ID'},
  {id: "fullName", label: 'Full Name'},
  {id: "email", label: 'Email'},
  {id: "mobile", label: 'Mobile'},
  {id: "userType", label: 'User Type'},
]

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  tableHeaderText: {
    color: theme.palette.secondary.main,
    fontWeight: "500",
  },
  tableCard: {
    marginTop: "20px"
  },
}));

const Users = () => {
  const styles = useStyles();
  const {getAllUsers} = useDb();
  const {enqueueSnackbar} = useSnackbar();

  const [page, setPage] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async () => {
    try {
      const {docs, empty = false} = await getAllUsers();
      let users = [];
      if (!empty) {
        docs.forEach((doc) => {
          const {first_name = "", last_name = "", email = "", mobile = "", userType = ""} = doc.data();
          const userData = {
            email,
            mobile,
            userType,
            id: doc.id,
            fullName: `${first_name} ${last_name}`,
          };
          users.push(userData);
        });
      }
      setAllUsers(users);
    } catch (error) {
      showMessage(error?.message, "error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const showMessage = (message = "", type = "info") => {
    enqueueSnackbar(message, {
      variant: type,
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  return (
    <div>
      <Typography variant="h5" className={styles.headerText}>Users</Typography>
      <Card className={styles.tableCard}>
        <TableContainer sx={{maxHeight: "100%"}}>
          <Table aria-label="users table">
            <TableHead>
              <TableRow>
                {
                  columns.map((column) => (
                    <StyledTableCell className={styles.tableHeaderText} key={column.id}>
                      {column.label}
                    </StyledTableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                allUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          component="div"
          count={allUsers.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
};

export default Users;