import React, {useState} from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import {
  Box,
  List,
  Divider,
  Toolbar,
  ListItem,
  IconButton,
  CssBaseline,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
} from "@mui/material";
import {
  Logout,
  ChevronLeft,
  ChevronRight,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {styled, useTheme} from "@mui/material/styles";

//Containers
import SendNotification from "../containers/Views/SendNotification";
import Dashboard from "../containers/Views/Dashboard";
import Users from "../containers/Views/Users";

import {useAuth} from "../contexts/AuthContext";
import routes from "../routes";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  color: "#fff",
  background: `linear-gradient(0deg, rgba(66, 79, 176, 0.89) 0%, rgba(60, 134, 244, 0.17151) 83.33%, rgba(60, 134, 244, 0) 100%), ${theme.palette.primary.main}`,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7.5)} + 1px)`,
  },
  color: "#fff",
  background: `linear-gradient(0deg, rgba(66, 79, 176, 0.89) 0%, rgba(60, 134, 244, 0.17151) 83.33%, rgba(60, 134, 244, 0) 100%), ${theme.palette.primary.main}`,
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: `linear-gradient(270deg, rgba(66, 79, 176, 0.89) 0%, rgba(60, 134, 244, 0.17151) 83.33%, rgba(60, 134, 244, 0) 100%), ${theme.palette.primary.main}`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const AdminLayout = () => {
  const theme = useTheme();
  const history = useHistory();
  const {adminSignOut} = useAuth();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = async () => {
    handleDrawerClose();
    await adminSignOut();
    history.push('/signin');
  };

  const navigateTo = (path = "") => {
    handleDrawerClose();
    history.push(path);
  };

  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && {display: "none"}),
            }}
          >
            <MenuIcon htmlColor="#fff"/>
          </IconButton>
          <img alt="app-logo" style={{height: 45, width: "auto"}} src={require("../assets/img/app-logo.png").default} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRight htmlColor={"#fff"}/> : <ChevronLeft htmlColor={"#fff"}/>}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{height: "inherit"}}>
          {
            routes.map((route) => (
              <ListItem button key={route.path} onClick={() => navigateTo(route.path)}>
                <ListItemIcon>
                  <route.icon htmlColor={"#fff"}/>
                </ListItemIcon>
                <ListItemText primary={route.name}/>
              </ListItem>
            ))
          }
        </List>
        <List>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <Logout htmlColor={"#fff"}/>
            </ListItemIcon>
            <ListItemText primary={"Logout"}/>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{flexGrow: 1, p: 3}}>
        <DrawerHeader />
        <Switch>
          <Route exact path="/admin/dashboard" component={Dashboard} />
          <Route exact path="/admin/users" component={Users} />
          <Route exact path="/admin/send-notification" component={SendNotification} />
          <Redirect from="/admin" to={{pathname: "/admin/dashboard"}} />
        </Switch>
      </Box>
    </Box>
  );
};

export default AdminLayout;