import React, {useState, useEffect} from "react";
import {
  Card,
  Grid,
  Button,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import {useSnackbar} from "notistack";
import {makeStyles} from "@mui/styles";

import {useDb} from "../../contexts/DatabaseContext";
import * as API from "../../services/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: "100%",
  }
};

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  formCard: {
    marginTop: "20px",
    padding: "20px",
  },
  inputClass: {
    marginBottom: "20px",
  },
}));

const SendNotification = () => {
  const styles = useStyles();
  const {getAllUsers, updateUserData} = useDb();
  const {enqueueSnackbar} = useSnackbar();

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [sending, setSending] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async () => {
    try {
      const { docs, empty = false } = await getAllUsers();
      let users = [];
      if (!empty) {
        docs.forEach((doc) => {
          const { first_name = "", last_name = "", email = "", pushTokens = [] } = doc.data();
          const userData = {
            label: `${first_name} ${last_name}${email ? ` (${email})` : ""}`,
            value: doc.id,
            pushTokens,
          };
          users.push(userData);
        });
      }
      setAllUsers(users);
    } catch (error) {
      showMessage(error?.message, "error");
    }
  };

  const handleChange = (event) => {
    const { target: { value } } = event;
    const hasAll = value.includes("select_all")
    if (hasAll && !isAllSelected) {
      setIsAllSelected(true);
      setSelectedUsers(allUsers.map(u => u.value));
    } else if (hasAll && isAllSelected) {
      setSelectedUsers([]);
      setIsAllSelected(false);
    } else {
      if (isAllSelected) {
        setIsAllSelected(false);
      }
      setSelectedUsers(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSending(true);
      const pTokens = allUsers
        .filter(
          u => u?.pushTokens.length && selectedUsers.includes(u.value)
        ).map(usr => usr.pushTokens).flat();
      const {data = {}} = await API.sendNotification({
        tokens: pTokens,
        title, message,
      });
      const {message: resMessage, data: notifResponseData = {}} = data;
      const {invalidTokens} = notifResponseData;
      removeInvalidTokens(invalidTokens);
      showMessage(resMessage, "success");
    } catch (error) {
      showMessage(error?.message, "error");
    } finally {
      setSending(false);
    }
  };

  const removeInvalidTokens = async (tokens = []) => {
    const invalidTokenUsers = allUsers.filter(u => u?.pushTokens.some(t => tokens.includes(t)));
    const updatedUsers = invalidTokenUsers.map(u => ({
      ...u,
      pushTokens: u.pushTokens.filter(t => !tokens.includes(t)),
    }));
    for (let ui = 0; ui < updatedUsers.length; ui++) {
      const usr = updatedUsers[ui];
      const {value: uId, pushTokens = []} = usr;
      await updateUserData(uId, {pushTokens});
    }
    getUsers();
  };

  const showMessage = (message = "", type = "info") => {
    enqueueSnackbar(message, {
      variant: type,
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  return (
    <div>
      <Typography variant="h5" className={styles.headerText}>Send Notification</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={styles.formCard}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{mb: 1}}>
                  <FormControl required sx={{ width: "100%" }}>
                    <InputLabel id="select-box-label">Select User</InputLabel>
                    <Select
                      multiple
                      id="person-select"
                      MenuProps={MenuProps}
                      value={selectedUsers}
                      onChange={handleChange}
                      labelId="select-box-label"
                      input={<OutlinedInput label="Select User" />}
                      renderValue={(selected) => `${selected.length} Selected`}
                    >
                      <MenuItem sx={{pl: 7}} value={"select_all"}>
                        <ListItemText primary={"Select All"}/>
                      </MenuItem>
                      {
                        allUsers.map((user) => (
                          <MenuItem key={user.value} value={user.value}>
                            <Checkbox
                              checked={selectedUsers.includes(user.value)}
                            />
                            <ListItemText primary={user.label} />
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sx={{mb: 1}} item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="title"
                    value={title}
                    label="Title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Grid>
                <Grid sx={{mb: 2}} item xs={12}>
                  <TextField
                    rows={6}
                    required
                    multiline
                    fullWidth
                    id="message"
                    value={message}
                    label="Message"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                  >
                    {sending ? "Sending..." : "Send Notification"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SendNotification;