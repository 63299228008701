import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

import AdminLayout from "../layout/Admin";
import SignIn from "./Auth/SignIn";
import UserAssessmentReport from "../layout/UserAssessmentReport";

import DatabaseProvider from "../contexts/DatabaseContext";
import { useAuth } from "../contexts/AuthContext";

const backroadTheme = createTheme({
  palette: {
    primary: {
      main: "#3CC8F4"
    },
    secondary: {
      main: "#51579C"
    }
  }
});

export default function Wrapper() {
  const { currentUser = {} } = useAuth();
  const { uid = "" } = currentUser;
  const { pathname } = window.location;

  // const redirectUrl =
  //   uid && pathname.includes("/admin")
  //     ? pathname
  //     : pathname.includes("user-assessment-report")
  //     ? ""
  //     : "";

  return (
    <BrowserRouter>
      <ThemeProvider theme={backroadTheme}>
        <DatabaseProvider>
          {/* <Redirect to={{ pathname: redirectUrl }} /> */}
          {!uid ? (
            <Switch>
              {pathname.includes("user-assessment-report") ? (
                <Route
                  path="/user-assessment-report"
                  component={UserAssessmentReport}
                />
              ) : (
                <>
                  <Route exact path="/" component={SignIn} />
                  <Route exact path="/signin" component={SignIn} />
                </>
              )}
            </Switch>
          ) : (
            <Route path="/admin" component={AdminLayout} />
          )}
        </DatabaseProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
