import axios from "axios";
import { result } from "lodash";
import * as config from "../config/config";

const apiInstance = axios.create({
  baseURL: config.backroadFirebaseFunctionUrl,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json"
  }
});

const sendNotification = (data = {}) => {
  return apiInstance.post("sendNotification", data);
};

const backpainApiInstance = axios.create({
  baseURL: config.backroadBaseUrl,
  timeout: 60 * 1000,
  params: {
    apikey: config.backroadAPIKey
  }
});

const getUserAssReport = async (params = {}) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  return fetch(
    "https://api.backroad.health/v1/?fn=getUserAssReport&apikey=113C7BABEBF4B872BEAC115F26EBB&session_id=123123123123",
    requestOptions
  )
    .then(result => console.log(result))
    .catch(error => console.log("error", error));
};

export { sendNotification, getUserAssReport };
