import React from "react";
import {
  Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  headerText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

const Dashboard = () => {
  const styles = useStyles();
  return (
    <div>
      <Typography variant="h5" className={styles.headerText}>Dashboard</Typography>
    </div>
  );
};

export default Dashboard;